<template>
  <div>
    <v-card class="pa-0 tab-item-card" flat>
      <v-img class="section-title-bg-img" src="../../assets/utilities.jpg"></v-img>
      <div class="text-center py-12">
        <span class="text-h3 text-sm-h2 text-md-h1">Utilities responsibilities</span>
      </div>
      <v-container class="px-5 py-5 pt-sm-12">
        <p>7.1 The landlord is not responsible for any utilities, broadband or council tax payments</p>
        <p>7.2 You, the tenants are responsible for</p>
        <ol>
          <li>Council tax and rate</li>
          <li>Water rate</li>
          <li>Gas</li>
          <li>Electricity</li>
          <li>TV License</li>
          <li>Broadband</li>
        </ol>
        <p>7.3 Add 'When you move in you will need to setup accounts with utility suppliers and broadband companies, the options are:</p>
        <p class="my-3 font-weight-medium text-body-1">Avoid delays</p>
        <p>We recommend you get your utilities and broadband order as early as possible to delay connections. At peak times it can take a couple of weeks before services like broadband can be connections.</p>
        <p>To help setup your bills and broadband, we can pass on your contact details on utility and broadband provided.  Please confirm if you require the agent to inform the suppliers to contact you.</p>
        <div class="d-flex flex-wrap justify-space-between">
          <v-switch
            class="mx-3"
            inset
            label="Virgin Media"
          ></v-switch>
          <v-switch
            class="mx-3"
            inset
            label="Sky"
          ></v-switch>
          <v-switch
            class="mx-3"
            inset
            label="BT"
          ></v-switch>
          <v-switch
            class="mx-3"
            inset
            label="USwitch"
          ></v-switch>
        </div>
      </v-container>
      <div class="orange darken-4 text-center py-12">
        <v-container class="px-5 py-8">
          <p class="white--text text-h6 text-sm-h5">Remember it is your legal right to chose which utility and broadband supplier you use, neither your agent or landlord can insist on who you choose to use or refuse a change of supplier.  Please note that some of the utility and broadband supplier your agent passed your information to my receive a commission if you take the service.</p>
        </v-container>
      </div>
    </v-card>
    <MainPagination 
      previousPage="/review/insurance"
      nextPage="/review/deposit"
    />
  </div>
</template>

<script>
export default {
  name: 'agreement-utilities'
}
</script>

<style>

</style>